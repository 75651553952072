import type { ReactNode } from 'react';

import RingLoader from '../loaders/RingLoader';

interface ButtonProps {
  text?: string;
  icon?: ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  square?: boolean;
  loading?: boolean;
  style?: 'black' | 'blue' | 'delete' | 'black-cancel';
  cancel?: boolean;
  fontSize?: 'text-sm' | 'text-xs' | 'text-base' | 'text-lg';
  extraClassname?: string;
}

const Button = ({
  text,
  icon,
  onClick,
  type = 'button',
  square,
  loading,
  style = 'black',
  cancel = false,
  fontSize = 'text-base',
  extraClassname,
}: ButtonProps) => {
  const hoverClassnames = () => {
    if (style === 'black' && !cancel)
      return 'hover:bg-gradient-to-r hover:from-blue-500 hover:to-teal-400';
    if (style === 'black-cancel' && !cancel) return 'hover:bg-red';
    if (style === 'black' && cancel) return 'hover:bg-red';
    if (style === 'blue') return 'hover:bg-black';
    if (style === 'delete') return 'hover:text-black';
    // Default
    return 'hover:bg-gradient-to-r hover:from-blue-500 hover:to-teal-400';
  };

  const classnames = () => {
    if (style === 'black' || style === 'black-cancel')
      return `${fontSize || 'text-base'} bg-black text-white`;
    if (style === 'blue')
      return `${fontSize || 'text-xs'} bg-light-blue text-white !py-1`;
    if (style === 'delete')
      return `${fontSize || 'text-xs'} text-red underline font-bold`;
    // Default
    return `${fontSize || 'text-base'} bg-black text-white`;
  };

  const classnamesLoading = () => {
    if (style === 'black' || style === 'black-cancel')
      return `text-white bg-black`;
    if (style === 'blue') return `bg-light-blue text-white`;
    if (style === 'delete') return `bg-white`;
    // Default
    return `text-white absolute bg-black`;
  };

  return (
    <button
      onClick={onClick}
      className={`relative flex flex-row items-center gap-x-2 justify-center ${loading ? 'cursor-default' : 'cursor-pointer'} ${extraClassname} ${classnames()} rounded-[5px]  ${
        square ? 'p-3' : 'px-4 py-2'
      } ${!loading && `${hoverClassnames()}`}`}
      type={type}
      disabled={loading}
    >
      {icon}
      {text && <span>{text}</span>}
      {loading && (
        <div
          className={`${classnamesLoading()} absolute rounded-[5px] top-0 left-0 size-full flex items-center justify-center`}
        >
          <div className="size-[30px]">
            <RingLoader />
          </div>
        </div>
      )}
    </button>
  );
};

export default Button;
