import type { DroppableProvided, DropResult } from '@hello-pangea/dnd';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { type ReactNode, useState } from 'react';

import type { ITask } from '../../types';
import type { IColumn } from '../../types/Projects';
import CreateTaskPopup from '../../views/Popups/CreateTaskPopup';
import SingleTaskPopup from '../../views/Popups/SingleTaskPopup';
import Button from '../inputs/Button';
import TaskCard from './TaskCard';

interface PanelColumnProps {
  tasks: ITask[];
  provided: DroppableProvided;
  isDraggingOver: boolean;
  columnTitle: string;
  isBacklog: boolean;
  onClickTask: (val: ITask | null) => void;
  onClickCreate: () => void;
}

const PanelColumn = ({
  tasks,
  provided,
  isDraggingOver,
  columnTitle,
  isBacklog,
  onClickCreate,
  onClickTask,
}: PanelColumnProps) => {
  return (
    <>
      <div
        className={`flex flex-col !rounded-[20px] w-[300px] px-4 py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.20)] h-fit ${isDraggingOver ? 'text-white bg-light-blue rounded-md' : 'text-dark-blue bg-light-gray-columns'}`}
      >
        <p className=" font-bold text-lg">{columnTitle}</p>
        <ul
          className={`list-none p-2`}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {tasks.map((t, index) => (
            <Draggable key={t.id} draggableId={t.id.toString()} index={index}>
              {(providedDraggable) => (
                <li
                  ref={providedDraggable.innerRef}
                  {...providedDraggable.dragHandleProps}
                  {...providedDraggable.draggableProps}
                  onClick={() => onClickTask(t)}
                  className="!cursor-pointer"
                >
                  <TaskCard
                    title={`${t.key} - ${t.title}`}
                    maxHours={t.max_hours_estimated!}
                    minHours={t.min_hours_estimated!}
                    priority={t.priority}
                    scheduledAt={t.scheduledAt}
                  />
                </li>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
        {isBacklog && (
          <Button
            style="blue"
            text="Create Item"
            fontSize="text-sm"
            extraClassname="mx-2"
            onClick={() => onClickCreate()}
          />
        )}
        <div className="px-4"></div>
      </div>
    </>
  );
};

interface PanelProps {
  title: string;
  titleChildren?: ReactNode;
  handleOnDragEnd: (result: DropResult) => void;
  data: IColumn[];
  refetch: () => void;
  isBacklog: boolean;
  project_id: string;
  maxHours: number;
  currentHours: number;
}

const Panel = ({
  title,
  titleChildren,
  data,
  refetch,
  isBacklog,
  project_id,
  maxHours,
  currentHours,
}: PanelProps) => {
  const [editPopup, setEditPopup] = useState<ITask | null>(null);
  const [createPopup, setCreatePopup] = useState<string>('');

  return (
    <>
      {/* POPUPS */}
      {editPopup && (
        <SingleTaskPopup
          closePopup={() => setEditPopup(null)}
          visible={!!editPopup}
          refetch={refetch}
          task={editPopup}
          isBacklog={isBacklog}
          projectMaxHours={maxHours}
          currentHours={currentHours}
        />
      )}
      {isBacklog && (
        <CreateTaskPopup
          closePopup={() => setCreatePopup('')}
          visible={!!createPopup}
          priority={createPopup}
          project_id={project_id}
          refetch={refetch}
        />
      )}
      <div className="w-full flex flex-col">
        <div className="flex flex-col justify-between gap-y-2">
          <p className="text-dark-blue font-inter text-xl font-bold">{title}</p>
          {titleChildren}
        </div>
        <div className="mt-5 w-max">
          <div className="flex flex-row w-full gap-x-5 md:px-0 px-1">
            {data.map((c) => (
              <Droppable key={c.id} droppableId={c.id}>
                {(provided, snapshot) => (
                  <PanelColumn
                    tasks={c.tasks.sort((a, b) => a.order - b.order)}
                    provided={provided}
                    isDraggingOver={snapshot.isDraggingOver}
                    columnTitle={c.column_name}
                    isBacklog={isBacklog}
                    onClickTask={setEditPopup}
                    onClickCreate={() => setCreatePopup(c.column_status || '')}
                  />
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Panel;
