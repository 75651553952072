import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { tokenStateAtom } from '../../states/atoms';
import Loader from '../loaders/RippleLoader';

const CheckAuth = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const setToken = useSetRecoilState(tokenStateAtom);

  const navigate = useNavigate();

  // const { t } = useTranslation('common');

  const getToken = (options: any) => {
    console.log('getToken');
    getAccessTokenSilently(options)
      .then((res) => {
        console.log('getToken1');
        setToken(res.access_token);
        navigate('/dashboard');
      })
      .catch((e) => {
        console.log('getToken2');
        console.log(e);
        logout();
      });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        },
      });
      return;
    }

    getToken(process.env.REACT_APP_AUTH0_AUDIENCE);
  }, [isLoading, isAuthenticated]);

  if (isLoading || !isAuthenticated) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  // if (
  //   !user?.user_roles.includes('Manager') &&
  //   !user?.user_roles.includes('Owner') &&
  //   !user?.user_roles.includes('Superadmin')
  // ) {
  //   return (
  //     <div className="flex h-screen w-screen flex-col items-center justify-center">
  //       <p>{t('global.not-authorized')}</p>
  //       <button onClick={() => logout()}>Go Back</button>
  //     </div>
  //   );
  // }

  return null;
};

export default CheckAuth;
