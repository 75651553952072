import { gql } from '@apollo/client';

export const MOVE_TASK = gql`
  mutation UpdateTask(
    $id: String!
    $order: Int
    $column_id: String
    $scheduledAt: String
  ) {
    updateTask(
      id: $id
      order: $order
      column_id: $column_id
      scheduledAt: $scheduledAt
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask(
    $project_id: String!
    $title: String!
    $description: String!
    $priority: String
    $minHours: Int
    $maxHours: Int
  ) {
    createTask(
      project_id: $project_id
      title: $title
      description: $description
      priority: $priority
      minHours: $minHours
      maxHours: $maxHours
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const SCHEDULE_TASK = gql`
  mutation ScheduleTask($id: String!) {
    scheduleTask(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const UNSCHEDULE_TASK = gql`
  mutation UnScheduleTask($id: String!) {
    unScheduleTask(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: String!
    $priority: String
    $description: String
    $minHours: Int
    $maxHours: Int
    $scheduledAt: String
  ) {
    updateTask(
      id: $id
      priority: $priority
      description: $description
      minHours: $minHours
      maxHours: $maxHours
      scheduledAt: $scheduledAt
    ) {
      status
      code
      data
      error
    }
  }
`;
