import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UPDATE_PROJECT_MAX_HOURS } from '../../apollo/Projects';
import Button from '../../components/inputs/Button';
import GenericInput from '../../components/inputs/GenericInput';
import { PROJECT_NOTIFICATIONS } from '../../config/Notifications';
import PopupLayout from '../../layouts/PopupLayout';

interface ProjectHoursPopupProps {
  visible: boolean;
  closePopup: () => void;
  initValue: number;
  projectId: string;
  setMaxHours: (val: number) => void;
}

interface IForm {
  maxHours: number;
}

const ProjectHoursPopup = ({
  visible,
  closePopup,
  initValue,
  projectId,
  setMaxHours,
}: ProjectHoursPopupProps) => {
  const [updateHours, { loading }] = useMutation(UPDATE_PROJECT_MAX_HOURS);

  const { register, setValue, handleSubmit, watch } = useForm<IForm>({
    defaultValues: { maxHours: initValue },
  });

  const { maxHours } = watch();

  useEffect(() => {
    setValue('maxHours', initValue);
  }, [initValue]);

  useEffect(() => {
    if (!visible) setValue('maxHours', initValue);
  }, [visible]);

  if (!visible) return <></>;

  const onSubmit = (form: IForm) => {
    updateHours({
      variables: { maxHours: Number(form.maxHours), id: projectId },
      onCompleted: () => {
        toast.success(PROJECT_NOTIFICATIONS.UPDATE_MAX_HOURS_OK);
        setMaxHours(maxHours);
        closePopup();
      },
      onError: () => {
        toast.error(PROJECT_NOTIFICATIONS.UPDATE_MAX_HOURS_ERROR);
      },
    });
  };

  return (
    <PopupLayout
      popupClassname="md:min-w-[570px]"
      handleClosePopup={closePopup}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-row gap-x-10"
      >
        <GenericInput
          register={{ ...register('maxHours', { required: true }) }}
          type={'number'}
          title="Total Scheduled Hours"
        />
        <Button text="Save" type="submit" loading={loading} />
      </form>
    </PopupLayout>
  );
};

export default ProjectHoursPopup;
