import dayjs from 'dayjs';

import CalendarIcon from '../../assets/calendar-date-end.svg';
import PriorityIcon from './PriorityIcon';

interface TaskPanelProps {
  title: string;
  scheduledAt?: string;
  priority?: string | null;
  minHours?: number;
  maxHours?: number;
}

const TaskCard = ({
  title,
  scheduledAt,
  priority,
  minHours,
  maxHours,
}: TaskPanelProps) => {
  return (
    <>
      <div className={`py-2 bg-transparent`}>
        <div className="flex flex-col w-full px-1.5 py-1 h-[75px] shadow-card text-sm rounded-[5px] justify-between bg-white text-dark-blue">
          <p className="card-title w-full">{title}</p>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-x-1">
              <div>
                <PriorityIcon priority={priority} />
              </div>
              {scheduledAt && (
                <div className="bg-light-blue text-white pt-0.5 pb-1 px-2 justify-center items-center text-xs rounded-[5px] flex flex-row gap-x-0.5">
                  <img
                    alt={'Calendar'}
                    src={CalendarIcon}
                    width={14}
                    height={14}
                  />
                  <span className="pt-0.5">
                    {dayjs(Number(scheduledAt)).format('MM/DD')}
                  </span>
                </div>
              )}
            </div>
            <div>
              {minHours && maxHours ? (
                <p className="font-bold">{`${minHours || '?'} - ${maxHours || '?'}h`}</p>
              ) : (
                <p className="font-bold">Evaluating</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCard;
