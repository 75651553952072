import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';

import FeaturesLogo from '../../assets/checklist.svg';
import HomeLogo from '../../assets/home.svg';
import logo from '../../assets/logo-devio.svg';
import LogoutIcon from '../../assets/logout.svg';
import { ROUTES } from '../../config/routes/config';
import Button from '../inputs/Button';

const Sidebar = () => {
  const { logout } = useAuth0();
  return (
    <>
      {/* Sidebar */}
      <div
        className={`fixed z-40 bg-white flex h-full flex-row transition-all duration-1000 w-[58px] shadow-[5px_4px_25px_0px_rgba(0,0,0,0.25)]`}
      >
        <nav className="flex flex-col justify-between bg-greenlight text-black w-full">
          <div className="relative my-6 lg:my-4">
            <NavLink to={ROUTES.DASHBOARD}>
              <img
                src={logo}
                alt="logo"
                className={`mx-auto mb-4 transition-all duration-1000 px-2`}
              />
            </NavLink>
            <div className="mt-12 md:mt-12 lg:mt-16">
              <ul className="flex flex-col items-center gap-2">
                <li id="dashboard" className="mb-0 md:my-3">
                  <NavLink
                    to={ROUTES.DASHBOARD}
                    id="dashboardNav"
                    end
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2'
                    }
                  >
                    <img
                      className="size-[24px]"
                      src={HomeLogo}
                      alt="dashboard"
                    />
                  </NavLink>
                </li>
                <li id="dashboard" className="mb-0 md:mb-6">
                  <NavLink
                    to={ROUTES.FEATURES_REQUEST}
                    id="featuresNav"
                    className={({ isActive }) =>
                      isActive
                        ? 'navLinkActive flex gap-2 items-center'
                        : 'navLinkInactive flex gap-2'
                    }
                  >
                    <img
                      className="size-[24px]"
                      src={FeaturesLogo}
                      alt="features"
                    />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4">
            <ul className="flex flex-col items-center gap-6">
              <li id="dashboard" className="">
                <Button
                  icon={
                    <img src={LogoutIcon} alt="logout" width={20} height={20} />
                  }
                  square
                  style="black-cancel"
                  onClick={() =>
                    logout({
                      logoutParams: {
                        returnTo:
                          process.env.REACT_APP_AUTH0_REDIRECT_URI || '/',
                      },
                    })
                  }
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* {logoutModal && <ModalLogout handleClose={() => setLogoutModal(false)} />} */}
    </>
  );
};

export default Sidebar;
