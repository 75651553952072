import type { ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { OptionProps, SingleValueProps } from 'react-select';
import Select, { components } from 'react-select';

import ArrowDownIcon from '../../assets/arrow-down.svg';
import PriorityIcon from '../features-request/PriorityIcon';

interface GenericInputProps {
  name: string;
  title?: string;
  placeholder?: string;
  validation?: any;
  options: PriorityOption[];
  onChangeUpdate?: (val: string) => void;
}

interface PriorityOption {
  value: string;
  label: string;
}

const Option = (props: OptionProps<PriorityOption>) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row gap-x-2">
        <PriorityIcon priority={props.label.toUpperCase()} />
        {props.label}
      </div>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<PriorityOption>) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex flex-row gap-x-2">
        <PriorityIcon priority={props.data.label.toUpperCase()} />
        {props.data.label}
      </div>
    </components.SingleValue>
  );
};

const PrioritySelectInput = ({
  name,
  title,
  placeholder,
  validation,
  options,
  onChangeUpdate,
}: GenericInputProps) => {
  const colourStyles = {
    control: () => ({
      // ...styles,
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      height: '100%',
      minWidth: '100%',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    option: (styles: any, { isDisabled, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isSelected ? 'black' : '',
        color: isSelected ? 'white' : '#000',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        '&:hover': {
          border: 'none',
          boxShadow: 'none',
          background: '#D9D9D9',
          color: 'black',
        },
      };
    },
    menu: (styles: any) => ({
      ...styles,
      textTransform: 'capitalize',
      backgroundColor: '#fff',
      border: '1px solid #E5E5E5',
      borderRadius: '7px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      marginTop: '4px',
      minWidth: '200px',
      zIndex: 10,
      right: '0px',
    }),
    menuList: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      padding: 0,
      borderRadius: '7px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: '#5A5A5A',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      textTransform: 'capitalize !important',
      color: '#000',
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    input: (styles: any) => ({
      ...styles,
      ring: '0px',
      borderColor: 'white',
      '--tw-ring-color': 'transparent',
    }),
  };
  const { control } = useFormContext();
  const Formatter = ({
    field: { onChange, value, ref },
  }: {
    field: ControllerRenderProps<any, any>;
  }) => (
    <>
      <Select
        styles={colourStyles}
        ref={ref}
        value={value}
        components={{
          DropdownIndicator: () => <img src={ArrowDownIcon} alt="" />,
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
        }}
        onChange={(val) => {
          onChange(val);
          if (onChangeUpdate) onChangeUpdate((val as any).value);
        }}
        options={options.filter((o) => o.value !== value?.value)}
        placeholder={placeholder}
      />
    </>
  );
  return (
    <div className={`flex flex-col gap-y-1 w-full`}>
      {title && <p className={`text-light-gray-text text-xs`}>{title}</p>}
      <Controller
        name={name}
        control={control}
        render={Formatter}
        rules={{ ...validation }}
      />
      {/* {children && children} */}
    </div>
  );
};

export default PrioritySelectInput;
