import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import { GET_ALL_PROJECTS } from '../apollo/Projects';
import Loader from '../components/loaders/RippleLoader';
import ProjectRequestsCard from '../components/projects/ProjectRequestsCard';
import type { IGetProjectsRes } from '../types/Projects';

interface ProjectList {
  image: string;
  client: string;
  project: string;
  slug: string;
}

function Dashboard() {
  const { user, error } = useAuth0();
  const [projects, setProjects] = useState<ProjectList[]>([]);
  const { loading } = useQuery<IGetProjectsRes>(GET_ALL_PROJECTS, {
    onCompleted: (data) => {
      const projectsRes = Array.from(
        data.getProjects.data.map((c) =>
          c.projects.map((p) => ({
            image: p.project_logo,
            client: c.client_name,
            project: p.project_key,
            slug: p.project_slug,
          })),
        ),
      );
      setProjects(projectsRes.flat());
    },
    onError: (errorProjectsRes) => {
      console.log(errorProjectsRes, 'error user');
    },
  });
  return (
    <>
      <div className="flex flex-col">
        {user && (
          <div className="text-dark-blue font-inter text-2xl font-semibold text-left">
            Welcome {user.name?.split('.')[0].toUpperCase()}!
          </div>
        )}
        <div>
          {/* {loading && <div>Loading...</div>} */}
          {error && <div>{error.message}</div>}
        </div>
        <div className="mt-10 flex md:flex-row flex-col justify-between items">
          <div className="w-full">
            <p className="text-dark-blue font-inter text-xl font-semibold leading-7 text-left ">
              Features Request Projects
            </p>
            <div className="mt-5 flex md:flex-row flex-col items-start gap-5">
              {loading && (
                <div className="w-full items-center justify-center">
                  <Loader />
                </div>
              )}
              {projects.map((p) => (
                <ProjectRequestsCard
                  key={p.slug}
                  client={p.client}
                  logo={p.image}
                  project={p.project}
                  slug={p.slug}
                />
              ))}
            </div>
          </div>
          {/* <div className='flex flex-col md:mt-0 mt-5 '>
          <p className='text-dark-blue font-inter text-xl font-semibold leading-7 mb-5 md:text-left text-center'>Your Agent</p>
          <Agent></Agent>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
