import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { GET_ALL_PROJECTS } from '../../apollo/Projects';
import Loader from '../../components/loaders/RippleLoader';
import ProjectRequestsCard from '../../components/projects/ProjectRequestsCard';
import type { IGetProjectsRes } from '../../types/Projects';

interface ProjectList {
  image: string;
  client: string;
  project: string;
  slug: string;
}

function FeaturesRequest() {
  const [projects, setProjects] = useState<ProjectList[]>([]);
  const { loading } = useQuery<IGetProjectsRes>(GET_ALL_PROJECTS, {
    onCompleted: (data) => {
      const projectsRes = Array.from(
        data.getProjects.data.map((c) =>
          c.projects.map((p) => ({
            image: p.project_logo,
            client: c.client_name,
            project: p.project_key,
            slug: p.project_slug,
          })),
        ),
      );
      setProjects(projectsRes.flat());
    },
    onError: (error) => {
      console.log(error, 'error user');
    },
  });
  return (
    <>
      <div className="flex flex-col">
        <div className="text-dark-blue font-inter text-2xl font-semibold text-left">
          Features Request
        </div>
        <div className="mt-10 flex md:flex-row flex-col justify-between">
          <div className="w-full">
            <div className="flex md:flex-row flex-col items-start gap-5">
              {loading && (
                <div className="w-full items-center justify-center">
                  <Loader />
                </div>
              )}
              {projects.map((p) => (
                <ProjectRequestsCard
                  client={p.client}
                  logo={p.image}
                  project={p.project}
                  slug={p.slug}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturesRequest;
