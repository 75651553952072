import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  DELETE_TASK,
  SCHEDULE_TASK,
  UNSCHEDULE_TASK,
  UPDATE_TASK,
} from '../../apollo/Tasks';
import ClockIcon from '../../assets/clock-icon.svg';
import Button from '../../components/inputs/Button';
import DescriptionInput from '../../components/inputs/DescriptionInput';
import GenericInput from '../../components/inputs/GenericInput';
import PrioritySelectInput from '../../components/inputs/PrioritySelectInput';
import {
  EXCEED_HOURS_ERROR_MESSAGE,
  PRIORITIES_OPTIONS,
} from '../../config/constants';
import { TASKS_NOTIFICATIONS } from '../../config/Notifications';
import PopupLayout from '../../layouts/PopupLayout';
import type { ITask } from '../../types';
import { formatTaskPopup } from '../../utils/tasks';
import GenericConfirmationPopup from './GenericConfirmationPopup';

interface SingleTaskPopupProps {
  visible: boolean;
  closePopup: () => void;
  refetch: any;
  task: ITask;
  isBacklog: boolean;
  projectMaxHours: number;
  currentHours: number;
}

interface IForm {
  title: string;
  priority?: { label: string; value: string };
  scheduledAt?: string;
  description?: string;
  createdAt: string;
  max_hours_estimated?: number;
  min_hours_estimated?: number;
  key: string;
}

const SingleTaskPopup = ({
  visible,
  closePopup,
  task,
  refetch,
  isBacklog,
  projectMaxHours,
  currentHours,
}: SingleTaskPopupProps) => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [deleteTask, { loading: loadingDelete }] = useMutation(DELETE_TASK);
  const [scheduleTask, { loading: loadingSchedule }] =
    useMutation(SCHEDULE_TASK);
  const [unscheduleTask, { loading: loadingUnschedule }] =
    useMutation(UNSCHEDULE_TASK);

  const methods = useForm<IForm>({
    defaultValues: { ...formatTaskPopup(task) },
    reValidateMode: 'onChange',
  });

  const {
    register,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const { min_hours_estimated: minHours, max_hours_estimated: maxHours } =
    watch();

  useEffect(() => {
    reset({ ...formatTaskPopup(task) });
  }, [task]);

  useEffect(() => {
    if (!visible) reset();
  }, [visible]);

  if (!visible) return <></>;

  const onEditField = (
    key: 'minHours' | 'maxHours' | 'priority' | 'description',
    value: string | number,
  ) => {
    updateTask({
      variables: {
        id: task.id,
        [key]: value,
        scheduledAt: task.scheduledAt,
      },
      onCompleted: () => {
        refetch();
      },
      onError: () => {
        toast.error(TASKS_NOTIFICATIONS.TASK_UPDATE_KO);
      },
    });
  };

  const onDelete = () => {
    deleteTask({
      variables: { id: task.id },
      onCompleted: () => {
        toast.success(TASKS_NOTIFICATIONS.TASK_DELETED_OK);
        refetch();
        setDeletePopup(false);
        closePopup();
      },
      onError: () => {
        setDeletePopup(false);
        toast.error(TASKS_NOTIFICATIONS.TASK_DELETED_KO);
      },
    });
  };

  const onScheduleClick = () => {
    if (!minHours || !maxHours) {
      toast.error(TASKS_NOTIFICATIONS.MOVE_NO_HOURS_ERROR);
      return;
    }
    if (isBacklog) {
      // SCHEDULE TASK
      if (currentHours > projectMaxHours) {
        toast.error(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_ERROR);
        return;
      }
      scheduleTask({
        variables: { id: task.id },
        onCompleted: () => {
          if (currentHours + (minHours || 0) > projectMaxHours)
            toast.warn(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_WARNING);
          else toast.success(TASKS_NOTIFICATIONS.TASK_SCHEDULED_OK);
          refetch();
          closePopup();
        },
        onError: (error) => {
          if (error.message === EXCEED_HOURS_ERROR_MESSAGE)
            toast.error(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_ERROR);
          else toast.error(TASKS_NOTIFICATIONS.TASK_SCHEDULED_KO);
        },
      });
    } else {
      // UNSCHEDULE TASK BACK TO BACKLOG
      unscheduleTask({
        variables: { id: task.id },
        onCompleted: () => {
          toast.success(TASKS_NOTIFICATIONS.TASK_UNSCHEDULED_OK);
          refetch();
          closePopup();
        },
        onError: (error) => {
          console.log(error);
          toast.error(TASKS_NOTIFICATIONS.TASK_UNSCHEDULED_KO);
        },
      });
    }
  };

  return (
    <>
      <PopupLayout popupClassname="md:w-[650px]" handleClosePopup={closePopup}>
        <FormProvider {...methods}>
          <form className="flex flex-col gap-x-1.5 gap-y-2.5">
            <div className="flex flex-row gap-y-2.5 gap-x-5">
              <div className="w-full flex flex-col gap-y-1.5">
                <p className="text-xl font-bold text-dark-blue">{`${task.key} - ${task.title}`}</p>
                <DescriptionInput
                  loading={false}
                  onSubmit={(val) => onEditField('description', val)}
                />
              </div>
              <div className="min-w-[240px] flex flex-col gap-y-2.5">
                <PrioritySelectInput
                  name="priority"
                  title="Priority"
                  placeholder={'Priority'}
                  options={PRIORITIES_OPTIONS}
                  onChangeUpdate={(val) => onEditField('priority', val)}
                />
                <GenericInput
                  register={{
                    ...register('min_hours_estimated', { required: true }),
                  }}
                  type={'number'}
                  title="Estimated Time Min."
                  placeholder="Hours"
                  error={errors.min_hours_estimated?.type}
                  onBlur={() => onEditField('minHours', Number(minHours || 0))}
                />
                <GenericInput
                  register={{
                    ...register('max_hours_estimated', { required: true }),
                  }}
                  type={'number'}
                  title="Estimated Time Max."
                  placeholder="Hours"
                  error={errors.max_hours_estimated?.type}
                  onBlur={() => onEditField('maxHours', Number(maxHours || 0))}
                />
                <GenericInput
                  register={{ ...register('createdAt') }}
                  type={'string'}
                  title="Created On"
                  readonly
                />
                <GenericInput
                  register={{ ...register('scheduledAt') }}
                  type={'string'}
                  title="Scheduled At"
                  readonly
                />
                <Button
                  icon={<img src={ClockIcon} alt="clock icon" />}
                  text={isBacklog ? 'Schedule task' : 'Unschedule task'}
                  style="blue"
                  fontSize="text-sm"
                  // onClick={onScheduleClick}
                  onClick={() => setSchedulePopup(true)}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-full">
              <Button
                onClick={() => setDeletePopup(true)}
                text="Delete Task"
                style="delete"
              />
            </div>
          </form>
        </FormProvider>
      </PopupLayout>
      <GenericConfirmationPopup
        text="Are you sure you want to delete this task?"
        description="This task will be deleted permanently and you will not be able to recover this information."
        onAccept={onDelete}
        loading={loadingDelete}
        closePopup={() => setDeletePopup(false)}
        visible={deletePopup}
        bgStyle="dark"
      />
      <GenericConfirmationPopup
        text={`Are you sure you want to ${isBacklog ? 'schedule' : 'unschedule'} this task?`}
        // description="This task will be deleted permanently and you will not be able to recover this information."
        onAccept={onScheduleClick}
        loading={loadingSchedule || loadingUnschedule}
        closePopup={() => setSchedulePopup(false)}
        visible={schedulePopup}
        bgStyle="dark"
      />
    </>
  );
};

export default SingleTaskPopup;
